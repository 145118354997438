<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { ddlHelper } from "@/utils";
import { required, email, sameAs } from "vuelidate/lib/validators";
import moment from "moment";
import { mapState } from "vuex";
import i18n from "@/i18n";
import { LOOKUP_MODULE } from '@/state/modules/lookup'
import { USER_MODULE } from '@/state/modules/user'
import { userMethods, lookupMethods } from "@/state/helpers";

const checkPassword = (value) => {
  if (value != null && value != "") {
    return /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&])(?=.{8,20})/.test(
      value
    );
  } else {
    return true;
  }
};

export default {
  page: {
    title: i18n.t("module.profile"),
  },
  components: { Layout, PageHeader },
  computed: {
    ...mapState(USER_MODULE, ["status"]),
    ...mapState(LOOKUP_MODULE),
    roleDdl() {
      return this.$store.state.lookup.roleDdl;
    },
    data() {
      return this.$store.state.user.details;
    },
    date() {
      return moment(this.$store.state.user.details.createdDateTime).format(
        "MMMM Do YYYY"
      );
    },
  },
  data() {
    return {
      title: i18n.t("module.profile"),
      items: [
        {
          text: i18n.t("module.dashboard"),
          href: "/",
        },
        {
          text: i18n.t("module.profile"),
          active: true,
        },
      ],
      submitted: false,
      userPassword: "",
      passwordConfirmation: "",
      statusDdl: ddlHelper.getActivationStatus(false, true),
    };
  },
  validations: {
    data: {
      name: { required },
      email: { required, email },
      isActive: { required },
    },
    userPassword: { checkPassword },
    passwordConfirmation: { sameAs: sameAs("userPassword") },
  },
  async created() {
    await this.getRole();
    await this.getProfile();
  },
  methods: {
    ...lookupMethods,
    ...userMethods,
    requestUpdate() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let data = {
          name: this.data.name,
          email: this.data.email,
          isActive: this.data.isActive,
          roleId: this.data.roleId,
          password: this.userPassword
        };
        this.updateProfile({ data });
      }
    },

    async getRole() {
      this.lookupRole({ hasOptionAll: false });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="requestUpdate">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">{{ $t('label.name') }}</label>
                    <input id="name" type="text" class="form-control" placeholder="Name" v-model="data.name"
                      :class="{ 'is-invalid': !$v.data.name.required }" />
                    <div v-if="!$v.data.name.required" style="color: #ff3d60; font-size: 80%">
                      <span v-if="!$v.data.name.required">{{
                        $t("message.requiredX", [$t("label.name")])
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="email">{{ $t('label.email') }}</label>
                    <input id="email" type="text" class="form-control" placeholder="" v-model="data.email" :class="{
                      'is-invalid':
                        !$v.data.email.required || !$v.data.email.email,
                    }" />
                    <div v-if="!$v.data.email.required || !$v.data.email.email" class="invalid-feedback">
                      <span v-if="!$v.data.email.required">{{
                        $t("message.requiredX", [$t("label.email")])
                      }}</span>
                      <span v-if="!$v.data.email.email">{{
                        $t("message.validX", [$t("label.email")])
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="isVerified">{{ $t('label.verified') }}</label>
                    <input id="isVerified" type="text" class="form-control" :placeholder="$t('label.verified')" :value="data.isVerified === true ? $t('label.verified') : $t('label.nonVerified')
                      " disabled />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="isActive">{{ $t('label.userStatus') }}</label>
                    <input id="isActive" type="text" class="form-control" :placeholder="$t('label.status')"
                      :value="data.isActive === true ? $t('label.active') : $t('label.inactive')" disabled />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="password">{{ $t('label.password') }}</label>
                    <input id="password" type="password" class="form-control"
                      :placeholder="$t('label.enter', [$t('label.password')])" v-model="userPassword"
                      :class="{ 'is-invalid': !$v.userPassword.checkPassword }" />
                    <div v-if="!$v.userPassword.checkPassword" style="color: #ff3d60; font-size: 80%">
                      <span v-if="!$v.userPassword.checkPassword">{{
                        $t("message.passwordErrorMessage")
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="passwordConfirmation">{{ $t('label.confirmationPassword') }}</label>
                    <input id="passwordConfirmation" type="password" class="form-control"
                      :placeholder="$t('label.enter', [$t('label.confirmationPassword')])" v-model="passwordConfirmation"
                      :class="{ 'is-invalid': !$v.passwordConfirmation.sameAs }" />
                    <div v-if="!$v.passwordConfirmation.sameAs" style="color: #ff3d60; font-size: 80%">
                      <span v-if="!$v.passwordConfirmation.sameAs">{{
                        $t("message.sameAs")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="createdDateTime">{{ $t('label.createdDateTime') }}</label>
                    <input id="createdDateTime" class="form-control"
                      :placeholder="$t('label.enter', [$t('label.createdDateTime')])" v-model="date" type="datetime"
                      disabled />
                  </div>
                </div>

                <!-- <div class="col-md-6">
                  <div class="form-group">
                    <label for="email">{{ $t('module.role') }}</label>
                    <div class="col-sm-5 py-2" v-for="role in roleDdl" v-bind:key="role.value">
                      <div class="form-check">
                        <b-form-checkbox type="checkbox" v-model="data.roleId" v-bind:value="role.value"
                          v-bind:id="role.value" v-if="role.value == data.roleId" disabled>
                          {{ role.text }}
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="mt-4 text-center" style="float: right">
                <button class="btn btn-primary w-md waves-effect waves-light" style="margin-right: 10px" type="submit"
                  :disabled="status.updateProfile">
                  {{ $t("label.updateX", [$t('module.profile')]) }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>